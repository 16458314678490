<template>
  <div class="banner-area">
    <transition :css="false" @enter="bnrEnter" @leave="bnrLeave" mode="out-in">
      <div ref="banner" :class="{banner: true, 'non-fixed': fixed}" v-if="!bannerHidden">
        <p>이모션글로벌과 위대함을 만들어보세요.</p>
        <ul>
          <li>
            <router-link to="/request"><span>프로젝트 문의</span></router-link>
          </li>
          <li>
            <a :href="url" download><span>회사소개서 다운로드</span></a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
  import {TweenMax} from "gsap";
  export default {
    name: "AppBanner",
    data() {
      return {
        fixed: false,
        bannerHidden: true
      };
    },
    mounted() {
      window.addEventListener("scroll", this.bannerHiddenCtr);
    },
    computed: {
      url() {
        return `${process.env.VUE_APP_API_URL}/api/v1/company`;
      }
    },
    methods: {
      /*async fileDown() {
        try {
          const response = await apiFileDown();
          let blob = new Blob([response.data]);
          let downloadUrl = window.URL.createObjectURL(blob);
          console.log(downloadUrl);
          let filename = "";
          let disposition = response.headers["content-disposition"];
          console.log(response);
          if (disposition && disposition.indexOf("attachment") !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
              matches = filenameRegex.exec(disposition);

            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        } catch (e) {
          console.log(e);
        }
      },*/
      bannerHiddenCtr() {
        const banner = document.querySelector(".banner-area");
        const fixedVal = banner.offsetTop - window.innerHeight;
        clearTimeout(this.windowScrollTimer);
        this.bannerHidden = true;
        if (fixedVal <= window.pageYOffset) {
          this.bannerHidden = false;
          this.fixed = true;
        } else {
          this.fixed = false;
        }
        this.windowScrollTimer = setTimeout(() => {
          if (window.innerHeight / 2 < window.pageYOffset) {
            this.bannerHidden = false;
          }
        }, 3000);
      },
      bnrEnter(el, done) {
        TweenMax.from(el, 0.3, {
          y: "100%",
          onComplete: () => {
            done();
          }
        });
      },
      bnrLeave(el, done) {
        TweenMax.to(el, 0.3, {
          y: "100%",
          onComplete: () => {
            done();
          }
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  .banner-area {
    z-index: 12;
    position: relative;
    height: $bottomBnrHeight;
  }
  .banner {
    will-change: transform;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    min-width: $globalWidth;
    width: 100vw;
    height: $bottomBnrHeight;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    background: $red;
    &.non-fixed {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      transform: translate(0, 0) !important;
    }
    p {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: -0.03em;
    }
    ul {
      display: flex;
      margin-top: 18px;
    }
    li {
      margin: 0 10px;
    }
    a {
      display: block;

      padding: 0 20px;
      border: 1px solid #fff;
      border-radius: 13px;
      color: #fff;
      span {
        font-size: 13px;
        line-height: 26px;
        font-weight: bold;
        letter-spacing: -0.03em;
      }
    }
  }
</style>
